<template>
  <div class="fixed inset-0 flex items-center justify-center">
    <div class="relative z-10 bg-white shadow-xl sm:mx-auto sm:w-2/3 md:w-1/2 lg:w-1/3 rounded-3xl">
      <img class="h-40 mx-auto" src="/assets/logo-rcp-white.png"/>
        <h2 class="max-w-sm mx-auto mt-8 text-2xl font-bold leading-9 tracking-tight text-center">{{ $t('login_title') }}</h2>
        <div class="w-full max-w-sm px-12 mx-auto sm:max-w-md">
          <form class="space-y-6" @submit.prevent="handleSubmit" ref="form">
            <div>
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900">{{ $t('login_email') }}</label>
              <div class="mt-2">
                <input id="email" name="email" type="email" autocomplete="email" :placeholder="$t('login_placeholder_email')" required
                class="block w-full rounded-md border-0 py-1.5 px-4 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-regal-blue sm:text-sm sm:leading-6 dark:text-black"
                @input="clearErrors"/>
                <div v-if="emailError" class="text-red-500">{{ emailError }}</div>
              </div>
            </div>
            <div>
              <label for="password" class="block text-sm font-medium leading-6 text-gray-900">{{ $t('login_password') }}</label>
              <div class="mt-2">
                <input id="password" name="password" type="password" autocomplete="current-password" :placeholder="$t('login_placeholder_password')" required class="block w-full rounded-md border-0 py-1.5 px-4 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-regal-blue sm:text-sm sm:leading-6 dark:text-black" />
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <input id="remember-me" name="remember-me" type="checkbox" class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-600" />
                <label for="remember-me" class="block ml-3 text-sm leading-6 text-gray-900">{{ $t('login_remember_me') }}</label>
              </div>

              <div class="text-sm leading-6">
                <NuxtLink class="font-semibold duration-300 text-regal-blue hover:text-regal-blue-bold hover:ease-in-out" :to="localPath('/forgot-password')">{{ $t('login_forgot_password') }}</NuxtLink>
              </div>
            </div>
            <div>
              <button type="submit" class="flex w-full justify-center rounded-md bg-regal-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-regal-blue-bold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-regal-blue hover:ease-in-out duration-300 hover:duration-150">{{ $t('login_title') }}</button>
            </div>
          </form>
          <div>
            <div class="relative flex justify-center mt-10 text-sm font-medium leading-6">
              <span class="px-6 text-gray-900">{{ $t('register_or_continue') }}</span>
            </div>
            <CardFooterConnection />
          </div>
          <p class="pb-8 mt-10 text-sm text-center text-gray-500"> {{ $t('login_register_label_hyperlink') }}
            <NuxtLink class="font-semibold duration-300 text-regal-blue hover:text-regal-blue-bold hover:ease-in-out" :to="localPath('/register')">{{ $t('login_register_hyperlink') }}</NuxtLink>
          </p>
        </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'auth',
  });
  import { loginUser } from '~/api/auth/User';
  import type { IUser } from '~/api/auth/interfaces/IUser';

  const localPath = useLocalePath();
  const form = ref<HTMLFormElement | null>(null);
  const emailError = ref<string | null>(null);
    const toast = useToast()

  const clearErrors = () => {
    emailError.value = null;
  };
  const isEmailValid = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  }

  const handleSubmit = async (event: Event) => {
    event.preventDefault();

    if (!form.value) {
      console.error('Invalid form element');
      return false;
    }

    const formData = new FormData(form.value);
    const userData: IUser = {
      email: formData.get('email') as string,
      password: formData.get('password') as string,
    };

    let hasErrors = false;

    if (!isEmailValid(userData.email)) {
      emailError.value = 'Email must be valid';
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const response = await loginUser(userData);
      if (response.code === 200) {
        toast.add({
          id: "success",
          color: "green",
          icon: "i-heroicons-check-circle",
          title: "Success",
          description: "Login successful. Redirecting...",
        })
        navigateTo('/dashboard');
      } else {
        if (Array.isArray(response._data.content)) {
          response._data.content.forEach((element: any) => {
            toast.add({
              id: 'error',
              title: 'Error.',
              icon: "i-heroicons-x-circle",
              color: "red",
              description: element.constraints,
            })
          });
        } else {
          toast.add({
              id: 'error',
              title: 'Error.',
              icon: "i-heroicons-x-circle",
              color: "red",
              description: response._data.content,
            })
        }
      }
    }
    catch (error) {
      toast.add({
        id: 'error',
        title: 'Error.',
        icon: "i-heroicons-x-circle",
        color: "red",
        description: 'Problem with the server for login connection',
      })
    }
};
</script>
